<script>
import { SuawMainContent, SuawInputGroup, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ResourceFrame from "@/features/resources/components/ResourceFrame.vue";

export default {
  name: "OrganizerFAQ",
  components: {
    SuawMainContent,
    SuawInputGroup,
    SuawParagraph,
    SuawHeading,
    ResourceFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ResourceFrame>
      <SuawInputGroup group-style="default" direction="column" group-gap="quadruple">
        <SuawHeading level="2" content="Organizer FAQ" />
        <SuawHeading level="3" content="In-Person Events" />
        <SuawHeading level="4" content="How do I find a place to host an in-person event?" />
        <SuawParagraph
          text="Search online for local cafes, coffee shops, bookstores, and public libraries in your area. Feel free to visit ahead of time to get a sense of the space. Check the availability of parking, WiFi, and outlets for laptops. Ensure the establishment is open at a convenient time so you can host your events at the same time each week. Keep in mind that venues should be public and free for writers, though it is okay for you and your writers to support the venue by purchasing a beverage or a snack if they are a cafe or coffee shop."
        />
        <SuawHeading level="4" content="What if no one shows up?" />
        <SuawParagraph
          text="We recommend making the commitment to yourself to get out of the house and write once a week. If others show up, that’s a bonus! Either way, you’re developing your writing habit and getting words on the page. Whether it’s for a group of a dozen writers or just for yourself, that’s exactly what a Shut Up & Write! event is about. In fact, our founder, Rennie Saunders, wrote at a cafe by himself several times at the start of our organization!"
        />
        <SuawHeading level="4" content="Do I have to make reservations?" />
        <SuawParagraph
          text="We recommend calling ahead at your venue of choice or even visiting to get a sense of how busy it is and whether the owner of the establishment requires a reservation."
        />
        <SuawHeading level="4" content="How do I move my in-person event online?" />
        <SuawParagraph
          text="Whether you want to move your in-person event online temporarily or permanently, please contact organizersupport@shutupwrite.com to get help and further training."
        />
        <SuawHeading level="4" content="What if I’m moving?" />
        <SuawParagraph
          text="Your event can move with you! Continuing to host Shut Up & Writes! in your new location is a great way to connect with others in your new community. Just create a new local event on shutupwrite.com and be sure to promote your event in your new location. Let your old group know that you’ll be leaving, and if possible, recruit a new organizer to take over your old event."
        />
        <SuawHeading level="3" content="Online Events" />
        <SuawHeading level="4" content="What equipment do I need to host an online event?" />
        <SuawParagraph
          text="Our online events are hosted via Zoom or similar video conferencing software. In order to use Zoom, you’ll need a computer capable of running Zoom, a stable internet connection, and a Zoom account. Since free Zoom accounts only allow for a 40 minute meeting duration, we ask that you purchase a paid Zoom account so that you can host an online event using Shut Up & Write’s hour-long format."
        />
        <SuawHeading level="4" content="What is a featured online event?" />
        <SuawParagraph
          text="We think these events are a great starting place for people to get a feel for our online format. These events are 90 minutes, with an hour of dedicated writing time and some optional socializing afterwards."
        />
        <SuawHeading level="4" content="What Zoom settings should I use for my online event?" />
        <SuawParagraph
          text="During the “Shut Up & Write!” phase of your event, default to a Mute setting for all of your attendees in order to maintain a quiet writing environment. Make sure to review our <a href='/resources/organizer-handbook'>Organizer Handbook</a>, which has more information on specific Zoom settings."
        />
        <SuawHeading level="3" content="General Questions" />
        <SuawHeading level="4" content="What do I get out of hosting Shut Up & Writes?" />
        <SuawParagraph
          text="First, it’s a great way to commit to your writing. It's also a good way to connect with other writers, not to mention a lot of fun! Organizers make a real difference in their communities, and we are here to support you every step of the way."
        />
        <SuawHeading level="4" content="What time should I pick for my event?" />
        <SuawParagraph
          text="Pick a day of the week and a time of day that works best for your own schedule! This way, hosting your event will be sustainable for you in the long run.."
        />
        <SuawHeading level="4" content="How do I promote my event?" />
        <SuawParagraph text="Print out flyers to leave at your chosen venue and around town. For online events, post on social media and ask friends to spread the word!" />
        <SuawHeading level="4" content="How do I find a co-host?" />
        <SuawParagraph
          text="Having a co-host is a great way to have ongoing support at your event. Try asking on social media or at the end of your event to find potential co-hosts."
        />
        <SuawHeading level="4" content="What if I have to cancel?" />
        <SuawParagraph
          text="Finding a co-host is a great way to have a backup in case you can’t make it. You can find one by asking at your event or by checking with local friends and acquaintances. But if you do have to cancel, whether it’s last-minute, with advance notice, or permanently, please be sure to update your event on its Event Page so that your writers are in the loop."
        />
        <SuawHeading level="4" content="What if I'm traveling?" />
        <SuawParagraph
          text="If you’re traveling, consider asking a regular attendee to take over hosting duties until you’re back. If you’re unable to find a co-host, you can host your event in your new location or simply cancel the sessions you’re unable to lead until your return."
        />
        <SuawHeading level="4" content="How do I get people to socialize?" />
        <SuawParagraph
          text="Asking about each person’s writing is a great way to get people talking. But keep in mind that some writers may be introverted or shy, which is okay! Shut Up & Write! is for everyone, whether they prefer to socialize or not."
        />
        <SuawHeading level="4" content="What if writers have questions?" />
        <SuawParagraph
          text="Anyone in our community can reach out to our Support team at questions@shutupwrite.com for help with any questions. Our help desk is available Monday through Friday, 9am - 5pm Pacific time."
        />
        <SuawHeading level="4" content="Can I host a Shut Up & Write! event only for a specific kind of writer?" />
        <SuawParagraph
          text="Shut Up & Write! events allow writers of all genres, skill levels, and stages of the writing process to support each other (not to mention ages, genders, etc.) We love that you want to support a specific kind of writer, but it is our belief that being as inclusive as possible is actually the best way to be supportive."
        />
        <SuawHeading level="4" content="What if someone is behaving disruptively at my event?" />
        <SuawParagraph
          text="Please refer to the guidelines mentioned above in our In-Person and Online sections, and reach out to organizersupport@shutupwrite.com. All community members must abide by our <a href='/resources/code-of-conduct'>Code of Conduct</a>. In the event that you witness illegal behavior, please reach out to your local authorities. Our help desk is available Monday through Friday, 9am - 5pm Pacific time."
        />
      </SuawInputGroup>
    </ResourceFrame>
  </SuawMainContent>
</template>
